<template>
  <div>
    <v-row class="pa-8 px-8">
      <v-spacer></v-spacer>
      <v-btn class="mr-2" color="primary" dark @click="dialog = true"
        ><v-icon>fa-plus</v-icon></v-btn
      >
    </v-row>
    <v-card>
      <v-card-text>
        <v-data-table :items="items" :headers="headers">
          <template v-slot:[`item.telemetria`]="{}">
            <v-icon>fa-check</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" width="600">
          <v-card>
            <v-card-title> Añadir dispositivo </v-card-title>

            <v-card-text class="mt-8">
              <v-form>
                <v-row>
                  <v-col>
                    <v-select
                      label="Seleccione uno de nuestros productos"
                      :items="products"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6" class="col-12 my-0 py-0">
                    <v-text-field outlined label="Id"></v-text-field>
                  </v-col>
                  <v-col lg="6" class="col-12 my-0 py-0">
                    <v-text-field outlined label="Alias"></v-text-field>
                  </v-col>
                  <v-col lg="12" class="col-12 my-0 py-0">
                    <v-text-field
                      outlined
                      label="GPS (lat, long)"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="6" class="col-12 my-0 py-0">
                    <v-text-field outlined label="IMP/M3"></v-text-field>
                  </v-col>
                  <v-col lg="6" class="col-12 my-0 py-0">
                    <v-text-field outlined label="IMP"></v-text-field>
                  </v-col>
                  <v-col lg="6" class="col-12 my-0 py-0">
                    <v-text-field
                      outlined
                      label="Periodo de transmisión"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="6" class="col-12 my-0 py-0">
                    <v-text-field
                      outlined
                      label="Periodo de reconfiguración"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="6" class="col-12 my-0 py-0">
                    <v-text-field
                      outlined
                      label="Transmisión local"
                    ></v-text-field>
                  </v-col>
                  <v-col lg="6" class="col-12 my-0 py-0">
                    <v-select outlined label="Usuario N2"></v-select>
                  </v-col>
                </v-row>
                <v-col> </v-col>
              </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="dialog = false" dark>
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: ["sadihasd", "salknda", "as98dsdasd", "sa8d8as9d"],
      color: { hex: null },
      isBrandBlank: false,
      dialog: false,
      headers: [
        { text: "Id", value: "id" },
        { text: "Alias", value: "alias" },
        { text: "Producto", value: "product" },
        { text: "Id usuario N2", value: "id_user_n2" },
        { text: "Usuario N2", value: "user_n2" },
        { text: "Telemetría", value: "telemetria" },
        { text: "Telecontrol", value: "Telecontrol" },
        { text: "Acciones", value: "actions" },
      ],
      items: [
        {
          id: "2JH3",
          alias: "Sensor de agua",
          product: "INHAC-213",
          id_user_n2: "87DSA",
          user_n2: "mterrones.ra@gmail.com",
          telemetria: true,
          telecontrol: true,
        },
      ],
    };
  },
};
</script>
